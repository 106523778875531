import { Checkbox, Col, Row, Typography } from 'antd'
import { FC, useEffect, useMemo, useState } from 'react'
import { useDLE } from 'rest-hooks'
import Checkable from 'src/sdk/components/checkable'
import { VerticalSpace } from 'src/sdk/components/layout'
import { SectionLoader } from 'src/sdk/components/loader'
import { Tag } from 'src/sdk/components/tag'
import { Money } from 'src/sdk/components/text'
import SmallText from 'src/sdk/components/text/SmallText'
import { MembershipCardEntity } from 'src/sdk/datasource/membership'
import { WalletBillingUse } from 'src/sdk/datasource/wallet/wallet'

interface IBillingUse {
  id?: Data.ID
  initialValue?: WalletBillingUse
  onChange: (value?: WalletBillingUse, subscriptions?: Data.ID[]) => void
  onFetch?: (hasMemberships: boolean) => void
}

const BillingUseTag: FC<{ billingUse: WalletBillingUse }> = ({ billingUse }) => {
  switch (billingUse) {
    case 'default':
      return (
        <Tag size={'small'} type={'primary'} shape={'asymmetric'}>
          Default
        </Tag>
      )
    case 'billing':
      return (
        <Tag size={'small'} type={'info'} shape={'asymmetric'}>
          Membership Dues
        </Tag>
      )
    case 'pos':
      return (
        <Tag size={'small'} type={'info'} shape={'asymmetric'}>
          Food and Beverage
        </Tag>
      )

    default:
      return null
  }
}

const SubscriptionBillingSelector: FC<{
  walletId?: Data.ID
  onChange: (selected?: Data.ID[]) => void
  onFetch?: (hasMemberships: boolean) => void
}> = ({ walletId, onChange, onFetch }) => {
  const { data, loading } = useDLE(MembershipCardEntity.list(), { type: 'subscription' })
  const [currentSelected, setSelected] = useState<Data.ID[]>([])
  const memberships = useMemo(() => data?.filter((i) => (i.status === 'active' || i.status === 'failed') && i.subscription && i.subscription.rate > 0), [data, walletId])

  const alreadyAssigned = useMemo(
    () => memberships?.filter((m) => m.subscription?.walletId === walletId).map((m) => m.subscriptionId) ?? [],
    [memberships, walletId],
  )

  const toggleAll = (checked: boolean) => {
    if (!memberships) return
    if (checked) {
      setSelected(memberships.filter((m) => !alreadyAssigned.includes(m.subscriptionId)).map((i) => i.subscriptionId))
    } else {
      setSelected([])
    }
  }
  const handleCheck = (item: Data.ID) => {
    setSelected((prev) => (prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]))
  }

  useEffect(() => {
    onChange(currentSelected)
  }, [currentSelected])

  useEffect(() => {
    setSelected(alreadyAssigned)
  }, [alreadyAssigned])

  useEffect(() => {
    onFetch && onFetch(!!memberships)
  }, [memberships])

  const list = useMemo(
    () =>
      memberships?.map((card) => (
        <Checkable
          title={card.title}
          onClick={() => handleCheck(card.subscriptionId)}
          checked={[...currentSelected, ...alreadyAssigned].includes(card.subscriptionId)}
          disabled={alreadyAssigned.includes(card.subscriptionId)}
          icon={'application/audit'}
          description={<Money suffix={`/ ${card.period}`}>{card.subscription?.rate}</Money>}
        />
      )),
    [memberships, currentSelected, handleCheck],
  )

  return loading ? (
    <SectionLoader />
  ) : memberships && memberships.length > 0 ? (
    <VerticalSpace>
      <Typography.Text strong>Choose membership:</Typography.Text>
      <Typography.Text type={'secondary'}>
        The above payment method will be used to process billing charges associated with the Membership subscription
        plan(s) selected below.
      </Typography.Text>
      <VerticalSpace size={16}>{list}</VerticalSpace>
      <Checkbox onChange={(evt) => toggleAll(evt.target.checked)}>
        Update all subscriptions and memberships with that payment method
      </Checkbox>
    </VerticalSpace>
  ) : null
}

const BillingUse: FC<IBillingUse> = ({ id, initialValue, onChange, onFetch }) => {
  const [billingUse, setBillingUse] = useState(initialValue)

  useEffect(() => {
    onChange(billingUse)
  }, [billingUse])

  return (
    <VerticalSpace>
      <Typography.Text strong>Use this payment method for:</Typography.Text>
      {initialValue === 'default' && (
        <SmallText>
          At least one payment method must be set as default. To update the billing use, a different payment method must
          be set as default.
        </SmallText>
      )}
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Checkable
            title={'Default'}
            disabled={initialValue === 'default'}
            onClick={() => setBillingUse('default')}
            checked={billingUse === 'default'}
            icon={'application/audit'}
            description={'This payment method will be used for any transactions without a specified payment method.'}
          />
        </Col>
        <Col span={24}>
          <Checkable
            title={'Membership Dues'}
            disabled={initialValue === 'default'}
            onClick={() => setBillingUse('billing')}
            checked={billingUse === 'billing'}
            icon={'application/credit-card'}
            description={'This payment method will be used for membership dues. Please select the memberships below.'}
          />
        </Col>
        <Col span={24}>
          <Checkable
            title={'Food & Beverage'}
            disabled={initialValue === 'default'}
            onClick={() => setBillingUse('pos')}
            checked={billingUse === 'pos'}
            icon={'application/credit-card'}
            description={'This payment method will be used for food and beverage transactions.'}
          />
        </Col>
      </Row>
      {billingUse === 'billing' && (
        <SubscriptionBillingSelector walletId={id} onChange={(ids) => onChange('billing', ids)} onFetch={onFetch} />
      )}
    </VerticalSpace>
  )
}

export { BillingUseTag, BillingUse as default }
